<template>
  <v-menu v-model="active" offset-y>
    <template v-slot:activator="{ on }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltipOn }">
          <v-btn text v-on="{ ...on, ...tooltipOn }" :disabled="disableButton">
            {{ listSelectorLabel }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <span>Select List</span>
      </v-tooltip>
    </template>
    <v-card v-if="listCount > 10">
      <v-card-title>{{ listCount > 10 ? 'Search for' : 'Select' }} list to edit</v-card-title>
      <v-card-text>
        <v-row v-if="listCount > 10">
          <v-col cols="12" md="6">
            <v-text-field v-model="listTitle" label="Filter Lists by Name" solo hide-details />
          </v-col>
          <v-col cols="12" md="6">
            <v-select v-model="owner" :items="ownerOptions" label="Owner/Editor" solo hide-details />
          </v-col>
        </v-row>
        <v-list dense elevation="1" class="pa-0">
          <v-list-item v-for="item in lists" :key="item._id" @click="select(item._id)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-list v-else dense elevation="1" class="pa-0">
      <v-list-item v-for="item in lists" :key="item._id" @click="select(item._id)">
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'

export default {
  setup (props, { root, emit }) {
    const active = ref(false)
    const disableButton = ref(false)
    const lists = ref([])
    const listCount = ref(0)
    const listSelectorLabel = ref('Select List')
    const listTitle = ref('')
    const owner = ref('')
    const ownerOptions = ref([
      { value: 'owner', text: 'My Lists' },
      { value: 'editor', text: 'Shared with me' }
    ])

    const user = computed(() => {
      const user = root.$store.state.user
      if ('spoof' in user) return user.spoof
      return user
    })

    function load () {
      let query = { type: 'list', $limit: 10 }

      switch (owner.value) {
        case 'owners':
          query.owner = user.value.directoryId
          break
        case 'editor':
          query.share = {
            $elemMatch: {
              user: user.value.directoryId,
              role: { $in: ['admin', 'editor'] }
            }
          }
          break
        default:
          query.$or = [
            { owner: user.value.directoryId },
            { share: { $elemMatch: { user: user.value.directoryId, role: { $in: ['admin', 'editor'] } } } }
          ]
      }
      if (listTitle.value !== '') {
        query.title = { $regex: listTitle.value, $options: 'i' }
      }
      root.$feathers.service('directory/list').find({ query }).then(({ total, data }) => {
        if (total === 0 && listTitle.value === '' && owner.value === '') disableButton.value = true
        else {
          listCount.value = total
          lists.value = data
        }
      })
    }

    watch([ listTitle, owner ], () => {
      load()
    })

    watch(active, () => {
      listTitle.value = ''
      owner.value = ''
    })

    onMounted(() => {
      load()
    })

    const select = (id) => {
      active.value = false
      emit('selected', id)
    }

    return { active, disableButton, lists, listCount, listSelectorLabel, listTitle, owner, ownerOptions, user, load, select }
  }
}
</script>
